nav {
    // change text color based on background
    filter: invert(1);
    mix-blend-mode: difference;

    svg {
        height: rem-calc(30);
    }

    span {
        vertical-align: middle;
    }
}

.navbar-light .navbar-toggler {
    color: black;
    border: none;
}

.home-nav {
    .nav-link {
        padding-left: 0;
        width: 100%;
        color: $black;
        font-size: rem-calc(26);
        transition: color 0.3s ease;

        &:hover {
            color: $color-highlight;
        }
    }
}

.nav-link {
    color: $color-text-primary;
}

.nav-link:focus, .nav-link:hover {
    color: $color-highlight;
}

  /* ------------------------------ DESKTOP VIEW ---------------------- */
  @include media-breakpoint-up(md) { 
    nav {
        &.frontpage {
        font-size: rem-calc(26);
        }
    }

    .offcanvas-start {
        width: rem-calc(550);
    }

    .offcanvas-body {
        padding-left: rem-calc(40);
    }
}