@font-face {
    font-family: "Silka";
    src: url('../../fonts/silka-regular-webfont.woff2') format("woff2");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Silka";
    src: url('../../fonts/silka-bold-webfont.woff2') format("woff2");
    font-weight: 700;
    font-style: normal;
  }

  
  @font-face {
    font-family: "Silka";
    src: url('../../fonts/silka-black-webfont.woff2') format("woff2");
    font-weight: 900;
    font-style: normal;
  }


body, h1, h2, h3, h4, h5 {
    font-family: "Silka";
}

body {
  font-size: rem-calc(16)
}

h1 {
  font-size: rem-calc(28);
  font-weight: 900;
  color: $color-primary;

  &.large {
    font-size: rem-calc(48);
  }
}

h2 {
    color: $color-primary;
    font-size: rem-calc(16);
    //font-style: italic;
}

.content {
  p {
    hyphens: auto;
 }
}

strong, .font-strong {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

nav {
  &.frontpage {
    font-size: rem-calc(20);
  }
}

/* ------------------------------ DESKTOP VIEW ---------------------- */
@include media-breakpoint-up(md) { 
  h1 {
    font-size: rem-calc(48);
    margin-bottom: rem-calc(25);

    &.large {
      font-size: rem-calc(92);
    }
  }

  h2 {
    font-size: rem-calc(20);
    margin-bottom: rem-calc(25);
  }

  body {
    font-size: rem-calc(20);
  }

}