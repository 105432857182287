.media:not(.blank-content) {
  min-height: rem-calc(200);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.media,
.media.blank-content {
  background-color: $black;
}


  /* ------------------------------ DESKTOP VIEW ---------------------- */
  @include media-breakpoint-up(md) { 
    .media {
        height: 100%;
        min-height: 100vh;
    }
  }