.quote {
    
  font-size: rem-calc(18);
  
    small {
      font-size: rem-calc(14);
    }

    &.left,
    &.right {
      background: $black;
      color: $white;
      padding: rem-calc(80px 20px 40px 20px);
      text-align: center;
      width: 100%;
      position: relative;
    }

    &.inline {
      text-align: center;
      color: $color-highlight;
    }
  }
  /* ------------------------------ TABLET ---------------------------- */

  @include media-breakpoint-up(sm) { 
    .quote {
      font-size: rem-calc(22);
      color: $black;

      &.inline {
        color: $black;
      }
    }
  }
  
  /* ------------------------------ DESKTOP VIEW ---------------------- */
  @include media-breakpoint-up(md) { 
    .quote {
        font-size: rem-calc(36);
    
        &.inline {
          text-align: center;
          max-width: 70%;
          margin: 0 auto;
    
          p {
            margin-bottom: 0;
          }
        }

        &.left,
        &.right {
            background: none; 
            position: absolute;
            width: 75%;
        }

        &.left {
            top: 25%;
            left: 50%;
            transform: translateX(-50%);
        }

        &.right {
          top: 25%;
          right: 50%;
          transform: translateX(50%);
        }
    
        small {
          font-size: rem-calc(18);
        }
      }
  }