table.finances,
table.results {
    font-size: rem-calc(12);
}

table {
    th {
        font-weight: 400;
    }

    tr.highlight {
        color: $color-highlight;
        font-weight: 700;

        &, th {
            font-weight: 700;
        }
    }
    td.highlight {
        background: $color-highlight-background;
        width: rem-calc(170);
    }

    .align-right {
        text-align: right;
    }

    &.finances tr:last-child,
    &.finances tr:first-child,
    &.finances tr:nth-child(8),
    &.finances tr:nth-child(7),
    &.finances tr:nth-child(14),
    
    &.results tr:last-child,
    &.results tr:nth-child(3),
    &.results tr:nth-child(5),
    &.results tr:nth-child(7),
    &.results tr:nth-child(16),
    &.results tr:nth-child(17),
    &.results tr:nth-child(23) {
        font-weight: 700;
    }

    &.results tr:nth-child(4) td,
    &.results tr:nth-child(5) td,
    &.results tr:nth-child(6) td,
    &.results tr:nth-child(7) td,
    &.results tr:nth-child(8) td,
    &.finances tr:nth-child(6) td,
    &.finances tr:nth-child(8) td,
    &.results tr:nth-child(17) td,
    &.results tr:nth-last-child(2) td,
    &.results tr:nth-last-child(6) td,
    &.results tr:last-child td {
        padding-top: rem-calc(45)
    }

}

/* ------------------------------ DESKTOP VIEW ---------------------- */
@include media-breakpoint-up(sm) { 
    table.finances,
    table.results {
        font-size: rem-calc(16);
    }
}


/* ------------------------------ DESKTOP VIEW ---------------------- */
@include media-breakpoint-up(md) { 
    table.finances,
    table.results {
        font-size: rem-calc(20);
    }
}