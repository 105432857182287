.content-wrapper {
    padding: rem-calc(55 20 20 20)
}

.content-wrapper-finance {
    padding: rem-calc(55 0 0 0)
}

.container-fluid {
    padding-left: 0;
}

.col,
[class*="col-"] {
    position: relative;
    padding:0;
}

.row {
    h1.large {
        padding: 0;
    }

    .intro {
        padding: 0;
        margin-bottom: rem-calc(25);
    }
}

.content-imprint {
    .row .col p:first-child {
        margin-bottom: rem-calc(5);
    }
}

/* ------------------------------ tablet VIEW ---------------------- */
@include media-breakpoint-up(sm) { 
    .content-wrapper,
    .content-wrapper-finance  {
        padding: rem-calc(70 40)
    }
}

/* ------------------------------ DESKTOP VIEW ---------------------- */
@include media-breakpoint-up(md) { 
    .content-wrapper,
    .content-wrapper-finance {
        padding: rem-calc(100 80);

        &.wrapper-home {
            padding: rem-calc(100 140);
        }
    }

    .finance {
        margin: rem-calc(50 0)
    }
}